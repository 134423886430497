














































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Props, User, Global, Reports, MapSettings } from '@/store';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import { Prop, Watch } from 'vue-property-decorator';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import PropEditor from '@/components/PropEditor.vue';
import API, { Types } from '@/modules/API';
import Gmap from '@/pages/mapView/components/map/GMap.vue';
import moment from 'moment';

@Component({
    components: {
        ConfirmDialog,
        PropEditor,
        Gmap
    }
})

export default class ConfigCabinets extends Vue {
    @Props.State('list') projectProperties;
    @Props.State('fixture_meta_device_options') fixture_meta_device_options;
    @Props.State('cabinet_meta_device_options') cabinet_meta_device_options;
    @Props.State('project_default_location') project_default_location;
    @User.State('project') project;
    @User.State('username') username;
    @Global.State('readonly_user') readonly_user;
    @Global.State('lang') lang;
    @Global.State('timezone') projectTimezone;
    @Global.Mutation('setPageTitle') setPageTitle;
    @Global.Action('throwNotify') throwNotify;
    @Reports.Getter('cabinets') cabinets;
    @Reports.State('reportsList') devices;
    @Reports.State('devicesCount') devicesCount;
    // @Reports.Getter('metaDeviceByDevice_map') metaDeviceByDevice_map;
    @Reports.Getter('commissioned_map') commissioned_map;
    // @Reports.Getter('metaDeviceByCabinet_map') metaDeviceByCabinet_map;
    // @Reports.Getter('device_and_meta_device_map') device_and_meta_device_map;
    @MapSettings.Mutation('setDeviceRepositioning') setDeviceRepositioning;

    cabinetDialog = false;
    editIndex = -1;
    editItem = {
        'id': '', 
        'name': '',
        'class_name': '',
        'meta.commission': {commissioned: false}, 
        'meta.location': {lat: 0.0, lng: 0.0}, 
        'meta.device': {cabinet_id: '', assigned_devices: '', controlled_devices: '', total_poles: '', controlled_poles: ''}
    };
    fields = [];
    headers = [];
    confirmDialog = false;
    confirmFunction = '';
    confirmMessage = '';
    confirmButton = '';
    property_exist = false;
    default_cabinet = null;
    new_location = null;
    prev_location = null;
    new_data = false;
    cabinets_data_map = new Map();
    changed = {};
    installed = {};
    confirmType = '';
    originalCabinets = new Map();
    optional_cabinet_id_options = [];
    devices_id_options = [];
    cabinets_id_options = [];
    search = '';
    show_new_message = false;
    new_cabinet_message = `I know that this ID doesn't exist in the system and I'm responsible for the consequences`;
    loading = true;

    mounted() {
        this.setPageTitle('Cabinets');
        this.generatePageData();
    }

    @Watch('devices')
    @Watch('devicesCount')
    generatePageData(){
        if (this.devices.length !== this.devicesCount) return;
        if (this.cabinets.length){
            this.setCabinetsData();
            this.setHeaders();
            this.setCabinetIdOptions();
        }
        this.loading = false;
    }

    setCabinetsData(){
        this.cabinets.forEach((cabinet) => {
            const local_cabinet = cloneDeep(cabinet);
            this.cabinets_data_map.set(local_cabinet.id, local_cabinet);
            this.$set(this.changed, local_cabinet.id, false);
            this.$set(this.installed, local_cabinet.id, cabinet['meta.commission'].commissioned);
            const original_cabinet = cloneDeep(cabinet);
            this.originalCabinets.set(cabinet.id, original_cabinet);
        });
        this.cabinets_data_map = new Map([...this.cabinets_data_map.entries()].sort((c1, c2) => c1[1].name.localeCompare(c2[1].name, undefined, {numeric: true, sensitivity: 'base'})));
    }

    setCabinetIdOptions(){
        this.devices_id_options = this.fixture_meta_device_options.cabinet_id || [];
        this.cabinets_id_options = cloneDeep(this.cabinet_meta_device_options.cabinet_id || []);
        
        this.optional_cabinet_id_options = this.devices_id_options.filter((cabinet_id) => !this.cabinets_id_options.includes(cabinet_id));
        this.optional_cabinet_id_options.sort((c1, c2) => c1.localeCompare(c2, undefined, {numeric: true, sensitivity: 'base'}));
    }

    dragCabinet(marker, new_location, prev_location){
        this.new_location = {...new_location};
        this.prev_location = {...prev_location};
        this.updateDragendPosition();
    }

    openCabinetDialog(){
        this.cabinetDialog = true;
    }

    openConfirmDialog(type){
        this.confirmDialog = true;
        this.confirmType = type;

        if (type === 'save'){
            this.confirmMessage = 'Are you sure you want to save all changes';
            this.confirmFunction = 'saveDeviceProperties';
            this.confirmButton = 'Save';
        }
    }

    checkNewCabinetID(){
        return !this.optional_cabinet_id_options.includes(this.editItem['meta.device'].cabinet_id) && !this.cabinets_id_options.includes(this.editItem['meta.device'].cabinet_id);
    }

    @Watch('search')
    updateCabinetID(){
        this.editItem['meta.device'].cabinet_id = this.search;
        this.show_new_message = this.checkUniqueId() === true && this.checkNewCabinetID();
    }

    closeConfirmDialog(){
        this.confirmDialog = false;
    }

    updateEditedCabinetLocation(cabinet, location){
        cabinet['meta.location'] = cloneDeep(location);
        cabinet.lat = cabinet['meta.location'].lat;
        cabinet.lng = cabinet['meta.location'].lng;
    }

    updateDragendPosition(){
        this.editItem['meta.location'] = cloneDeep(this.new_location);
        this.$refs.map['setDragMarker'](null);
        const cabinet = this.cabinets_data_map.get(this.editItem.id);
        this.updateEditedCabinetLocation(cabinet, this.new_location);
    }

    setHeaders(){
        this.fields = [
            {text: 'Installed', property: 'meta.commission', value: 'commissioned', type: 'checkbox', display: true},
            {text: 'Cabinet Name', value: 'name', rules: [], required: true, disabled: true},
            {text: 'Cabinet Id', value: 'cabinet_id', property: 'meta.device', rules: [this.checkUniqueId], required: true, disabled: false, display: true}, 
            {text: 'Cabinet Type', value: 'class_name', type: 'textfield', rules: [], disabled: true, display: true}, 
            {text: 'Total Fixtures', property: 'meta.device', value: 'assigned_devices', rules: [() => this.checkTypeNumber('assigned_devices', 'meta.device'), () => this.checkPositiveNumber('assigned_devices', 'meta.device')], disabled: false, display: true},
            {text: 'Controlled Fixtures', property: 'meta.device', value: 'controlled_devices', rules: [() => this.checkTypeNumber('controlled_devices', 'meta.device'), () => this.checkPositiveNumber('controlled_devices', 'meta.device')], disabled: false, display: true},
            {text: 'Total Poles', property: 'meta.device', value: 'total_poles', rules: [() => this.checkTypeNumber('total_poles', 'meta.device'), () => this.checkPositiveNumber('total_poles', 'meta.device')], disabled: false, display: true},
            {text: 'Controlled Poles', property: 'meta.device', value: 'controlled_poles', rules: [() => this.checkTypeNumber('controlled_poles', 'meta.device'), () => this.checkPositiveNumber('controlled_poles', 'meta.device')], disabled: false, display: true},
            {text: 'Latitude', value: 'lat', property: 'meta.location', rules: [], required: false, disabled: true, display: false},
            {text: 'Longitude', value: 'lng', property: 'meta.location', rules: [], required: false, disabled: true, display: false}
        ];
        this.headers = this.fields.filter((field) => field.display);
    }

    checkTypeNumber(field, property?){
        const value = property ? this.editItem[property][field] : this.editItem[field];
        return ((!(/^[0-9]*$/.test(value)) || isNaN(+value)) && this.$t('This field must be of type number')) || true;
    }

    checkPositiveNumber(field, property?){
        const value = property ? this.editItem[property][field] : this.editItem[field];
        return (+value < 0 && this.$t('This field must be positive number')) || true;
    }

    checkUniqueValue(field, property){
        return ([...this.cabinets_data_map.values()].some((cabinet, index) => cabinet[property][field] ? (cabinet[property][field].toLowerCase() === this.editItem[property][field].toLowerCase() && index !== this.editIndex) : false) 
            && this.$t(`Cabinet with same ${field} already exists`)) || true;
    }

    checkUniqueId(){
        return !this.editItem['meta.device'].cabinet_id
            ? this.$t('Required field')
            : this.cabinets_id_options.includes(this.editItem['meta.device'].cabinet_id) &&
                this.cabinets_data_map.get(this.editItem.id)['meta.device'].cabinet_id !== this.editItem['meta.device'].cabinet_id
                ? this.$t(`Cabinet with same id already exists`)
                : true;
    }
    

    requiredField(field, property?){
        if (property) return !!this.editItem[property][field] || this.$t('Required field');
        return !!this.editItem[field] || this.$t('Required field');
    }

    get mapDevices(){
        let cabinet = this.cabinets_data_map.get(this.editItem.id);
        if (!cabinet['meta.commission'].commissioned){
            cabinet = cloneDeep(cabinet);
            cabinet['meta.commission'].commissioned = true;
            if (!cabinet.has_valid_location) this.updateEditedCabinetLocation(cabinet, this.editItem['meta.location']);
        }
        return [cabinet];
    }

    editCabinet(item, index) {
        this.editIndex = index;
        this.editItem.id = item.id;
        this.editItem.name = item.name;
        this.editItem.class_name = Utils.hasCabinetClass(item.class_name) 
            ? this.$t('Regular')
            : Utils.hasVirtualCabinetClass(item.class_name, item['meta.device'])
                ? this.$t('Virtual')
                : '',
        this.copyValues(this.editItem, item);
        this.openCabinetDialog();
    }

    saveCabinet(){
        const cabinet = this.cabinets_data_map.get(this.editItem.id);
        const editItemPattern = this.getEditItemPattern(cabinet);
        if (this.differentObjects(this.editItem, editItemPattern)){
            const cabinet = this.cabinets_data_map.get(this.editItem.id);
            this.copyValues(cabinet, this.editItem);
            cabinet.lat = cabinet['meta.location'].lat;
            cabinet.lng = cabinet['meta.location'].lng;
        }
    }

    copyValues(obj1, obj2){
        obj1['meta.device'].cabinet_id = obj2['meta.device'].cabinet_id || '';  
        obj1['meta.device'].assigned_devices = obj2['meta.device'].assigned_devices || ''; 
        obj1['meta.device'].controlled_devices = obj2['meta.device'].controlled_devices || ''; 
        obj1['meta.device'].total_poles = obj2['meta.device'].total_poles || ''; 
        obj1['meta.device'].controlled_poles = obj2['meta.device'].controlled_poles || ''; 
        obj1['meta.location'] = Utils.isValidLocation(obj2['meta.location']) 
            ? cloneDeep(obj2['meta.location'])
            : cloneDeep(this.project_default_location);
        obj1['meta.commission'].commissioned = obj2['meta.commission'].commissioned;
    }

    saveChanges(){
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
            const originalItem = this.originalCabinets.get(this.editItem.id);
            this.editItem['meta.device'] = {
                cabinet_id: Utils.removeLeadingZeroesAndSpaces(this.editItem['meta.device'].cabinet_id),
                assigned_devices: Utils.removeLeadingZeroesAndSpaces(this.editItem['meta.device'].assigned_devices),
                controlled_devices: Utils.removeLeadingZeroesAndSpaces(this.editItem['meta.device'].controlled_devices),
                total_poles: Utils.removeLeadingZeroesAndSpaces(this.editItem['meta.device'].total_poles),
                controlled_poles: Utils.removeLeadingZeroesAndSpaces(this.editItem['meta.device'].controlled_poles)
            };
            this.$set(this.changed, this.editItem.id, this.differentObjects(this.editItem, this.getEditItemPattern(originalItem)));
            this.$set(this.installed, this.editItem.id, this.editItem['meta.commission'].commissioned);
            this.new_data = Object.values(this.changed).some((value) => value === true);
            this.saveCabinet();
            this.cabinets_id_options = [...this.cabinets_data_map.values()]
                .filter((cabinet_data) => cabinet_data['meta.device'].cabinet_id)
                .map((cabinet_data) => cabinet_data['meta.device'].cabinet_id);
            this.optional_cabinet_id_options = this.devices_id_options.filter((cabinet_id) => !this.cabinets_id_options.includes(cabinet_id));
            this.optional_cabinet_id_options.sort((c1, c2) => c1.localeCompare(c2, undefined, {numeric: true, sensitivity: 'base'}));
            this.close('cabinetDialog', true);
            this.show_new_message = false;
        }
    }

    getEditItemPattern(cabinet){
        return {
            'id': cabinet.id, 
            'name': cabinet.name,
            'class_name': Utils.hasCabinetClass(cabinet.class_name) 
                ? this.$t('Regular')
                : Utils.hasVirtualCabinetClass(cabinet.class_name, cabinet['meta.device'])
                    ? this.$t('Virtual')
                    : '',
            'meta.commission': {commissioned: cabinet['meta.commission'].commissioned}, 
            'meta.location': {...cabinet['meta.location']}, 
            'meta.device': {
                cabinet_id: cabinet['meta.device'].cabinet_id, 
                assigned_devices: cabinet['meta.device'].assigned_devices !== undefined ? cabinet['meta.device'].assigned_devices : '',
                controlled_devices: cabinet['meta.device'].controlled_devices !== undefined ? cabinet['meta.device'].controlled_devices : '', 
                total_poles: cabinet['meta.device'].total_poles !== undefined ? cabinet['meta.device'].total_poles : '', 
                controlled_poles: cabinet['meta.device'].controlled_poles !== undefined ? cabinet['meta.device'].controlled_poles : ''
            }
        };
    }

    differentObjects(obj1, obj2){
        return !(isEqual(obj1, obj2));
    }

    close(dialog, edited) {
        this[dialog] = false;
        if (!edited){
            const cabinet = this.cabinets_data_map.get(this.editItem.id);
            const original_cabinet = this.originalCabinets.get(this.editItem.id);
            const meta_location = original_cabinet['meta.location'];
            this.updateEditedCabinetLocation(cabinet, meta_location);
        }
        this.editIndex = -1;
        this.editItem = {'id': '', 'name': '', 'class_name': '', 'meta.commission': {commissioned: false}, 'meta.location': {lat: 0.0, lng: 0.0}, 'meta.device': {cabinet_id: '', assigned_devices: '', controlled_devices: '', total_poles: '', controlled_poles: ''}};
    }

    getHeaderValue(cabinet, property, field){
        if (field === 'class_name') {
            if (!cabinet.class_name) return '';
            return cabinet.class_name.toLowerCase().includes('virtual_cabinet')
                ? this.$t('Virtual')
                : this.$t('Regular');
        }
        if (!property) return cabinet[field];
        if (typeof cabinet[property][field] === 'boolean') return cabinet[property][field] ? this.$t('Yes') : this.$t('No');
        return cabinet[property][field] ? typeof cabinet[property][field] === 'string' && cabinet[property][field].includes(':') ? cabinet[property][field] : this.$t(cabinet[property][field]) : '';
    }

    getOriginalCabinetIds(){
        return [...this.originalCabinets.values()]
            .filter((cabinet_data) => cabinet_data['meta.device'].cabinet_id)
            .map((cabinet_data) => cabinet_data['meta.device'].cabinet_id);
    }

    getCurrentCabinetIds(){
        return [...this.cabinets_data_map.values()]
            .filter((cabinet_data) => cabinet_data['meta.device'].cabinet_id)
            .map((cabinet_data) => cabinet_data['meta.device'].cabinet_id);
    }

    async saveDeviceProperties() {
        const cabinetIdsChanged = Object.entries(this.changed).filter(([cabinet_id, value]) => value).map(([cabinet_id, value]) => cabinet_id);
        const cabinetsChanged = [...this.cabinets_data_map.keys()].filter((cabinet_id) => cabinetIdsChanged.includes(cabinet_id)).map((cabinet_id) => this.cabinets_data_map.get(cabinet_id));
        Object.entries(this.changed).forEach(([cabinet_id, changed]) => this.$set(this.changed, cabinet_id, false));
        const failed_log = {'meta.location': [], 'meta.commission': [], 'meta.device': []};
        
        const original_ids = this.getOriginalCabinetIds();
        original_ids.sort((c1, c2) => c1.localeCompare(c2, undefined, {numeric: true, sensitivity: 'base'}));
        const new_ids = this.getCurrentCabinetIds();
        new_ids.sort((c1, c2) => c1.localeCompare(c2, undefined, {numeric: true, sensitivity: 'base'}));
        
        const slices = Math.ceil(cabinetsChanged.length / 5);
        let startIndex = 0, endIndex = Math.min(cabinetsChanged.length, 5);
        for (let i = 0; i < slices; i++){
            const current = cabinetsChanged.slice(startIndex, endIndex);
            await Promise.all(current.map(async (device) => {
                if (this.differentObjects(device['meta.device'], this.originalCabinets.get(device.id)['meta.device'])){
                    // Object.entries(device['meta.device']).forEach(([field, value]) => {
                    //     device['meta.device'][field] = Utils.removeLeadingZeroesAndSpaces(value);
                    // });

                    try{
                        device['meta.device'] = PropUtils.getUpdatedUppercaseFields(device['meta.device']);
                        await this.updateMetaDevice(device);
                        this.originalCabinets.get(device.id)['meta.device'] = {...device['meta.device']};
                        this.updateStore('meta.device', {...device['meta.device']}, device.id);
                    }catch (e){
                        failed_log['meta.device'].push(device.id);
                        this.$set(this.changed, device.id, true);
                    }
                }
                if (this.differentObjects(device['meta.location'], this.originalCabinets.get(device.id)['meta.location'])){
                    try{
                        await this.updateLocation(device);
                        this.originalCabinets.get(device.id)['meta.location'] = {...device['meta.location']};
                        this.$set(this.changed, device.id, false || this.changed[device.id]);
                    }catch (e){
                        failed_log['meta.location'].push(device.id);
                        this.$set(this.changed, device.id, true);
                    }
                }
                if (this.differentObjects(device['meta.commission'], this.originalCabinets.get(device.id)['meta.commission'])){
                    try{
                        await this.updateCommission(device);
                        this.$set(this.changed, device.id, false || this.changed[device.id]);
                        this.updateStore('meta.commission', {...device['meta.commission']}, device.id);
                    }catch (e){
                        failed_log['meta.commission'].push(device.id);
                        this.$set(this.changed, device.id, true);
                    }
                } 
            }));          
            startIndex = endIndex;
            endIndex = Math.min(cabinetsChanged.length, endIndex + 5);
        }
        this.new_data = Object.values(this.changed).some((value) => value === true);
        if (!this.new_data){
            if (JSON.stringify(original_ids) !== JSON.stringify(new_ids)){
                this.updateSearchProperty();
            }
            this.throwNotify({
                type: 'success',
                title: `${this.$t('Success')}!`,
                text: this.$t(`All changes have been saved successfully`)
            });
        }else{
            this.throwNotify({
                type: 'warning',
                title: `${this.$t('Warning')}!`,
                text: this.$t(`Some changes not saved`)
            });
        }
        this.confirmDialog = false;
    }

    updateSearchProperty(){
        this.cabinet_meta_device_options.cabinet_id = this.cabinets_id_options;
    }

    async updateMetaDevice(device){
        const body = {
            name: 'meta.device',
            objectID: device.id,
            objectType: Types.DEVICES
        };
        (this.$refs.propEditor as PropEditor).save(
            device['meta.device'],
            body
        );
    }

    async updateLocation(device){
        const meta_location = {...device['meta.location']};
        meta_location.lat = meta_location.lat.toString();
        meta_location.lng = meta_location.lng.toString();
        const body = {
            name: 'meta.location',
            objectID: device.id,
            objectType: Types.DEVICES
        };
        (this.$refs.propEditor as PropEditor).save(
            meta_location,
            body,
            async () => this.updateAddress({...device['meta.location']}, device.id)
        );
    }

    updateStore(field, value, device_id){
        const original_device = this.devices.find((device) => device.id === device_id);
        original_device[field] = value;
        // if (field === 'meta.device'){
        //     this.updateMetaDeviceStoreData(original_device, value);
        // }else if (field === 'meta.commission'){
        //     this.commissioned_map.set(original_device.id, value);
        // }
    }

    // updateMetaDeviceStoreData(device, value){
    //     this.metaDeviceByDevice_map.set(device.id, value);
    //     this.metaDeviceByCabinet_map.set(device.id, value);
    //     this.device_and_meta_device_map.set(device.id, [device, value]);
    // }

    async updateAddress(location, device_id){
        const original_device = this.devices.find((device) => device.id === device_id);
        original_device['meta.location'] = location;
        const apikey = await JSON.parse(localStorage.getItem('user')).apikey;
        const url = 'device/update_estimated_address';
        const body = {
            device_id: original_device.id,
            company_id: this.$store.state.User.project.company,
            project_id: this.$store.state.User.project.id,
            lat: location.lat,
            lng: location.lng
        };

        const response = await API.dashboardAPI(url, 'POST', {}, body);
        if (response && response.address) {
            const meta_device = original_device['meta.device'];
            const uppercase_address = PropUtils.findMatchedUppercaseField(meta_device, 'cabinet_address');
            if (uppercase_address) meta_device[uppercase_address] = response.address;
            meta_device.cabinet_address = response.address;
            original_device['meta.device'] = meta_device;
            this.originalCabinets.get(original_device.id)['meta.device'] = {...meta_device};
            this.cabinets_data_map.get(original_device.id)['meta.device'] = {...meta_device};
            // this.updateMetaDeviceStoreData(original_device, meta_device);
        }
    }

    async updateCommission(device){
        device['meta.commission'].commissioned = device['meta.commission'].commissioned;
        device['meta.commission'].commissioner = this.username;
        device['meta.commission'].date = moment().tz(this.projectTimezone).format('DD.MM.YYYY HH:mm:ss');
        
        const body = {
            name: 'meta.commission',
            objectID: device.id,
            objectType: Types.DEVICES
        };
        (this.$refs.propEditor as PropEditor).save(
            device['meta.commission'],
            body
        );
    }

}
